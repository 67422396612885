import React from 'react'

import svg from '../images/contact.svg'

const Contact = () => {
  return (
    <section className="form-section" id="contact">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div
              className="inner-column"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="800"
            >
              <div className="image">
                <img src={svg} alt="" />
              </div>
            </div>
          </div>

          <div className="form-column col-lg-6 col-md-12 col-sm-12">
            <div
              className="inner-column"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="800"
            >
              <div className="sec-title">
                <div className="title">Contact</div>

                <div className="separator">
                  <span></span>
                </div>
                <h2>Un projet à réaliser sur le web ?</h2>
              </div>
              <div className="text">N'hésitez pas à nous contacter !</div>
              <div className="default-form">
                <form
                  name="contact"
                  method="POST"
                  action="/success"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                      <input
                        type="text"
                        name="Nom"
                        placeholder="Nom"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <textarea name="message" placeholder="Message"></textarea>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <button
                        className="theme-btn btn-style-two"
                        type="submit"
                        name="submit-form"
                      >
                        <span className="txt">Envoyer</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
