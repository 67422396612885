import React from 'react'

const data = [
  {
    id: 1,
    icon: 'flaticon-picture',
    title: 'Site vitrine ou e-commerce',
    description:
      'Refonte et conception sur mesures ou 100% personnalisables et responsive',
  },
  {
    id: 2,
    icon: 'flaticon-atom',
    title: 'Un site fluide grâce a React',
    description:
      'ReactJS permet la création de site fluide, rapide et optimisé pour toute les plateformes',
  },
  {
    id: 3,
    icon: 'flaticon-computer',
    title: 'Gestion de contenu',
    description:
      'Possibilitées de gérer le contenu de votre site par vous même grâce a des API comme Contentful',
  },
]

const offers = data.map((offer, index) => {
  return (
    <div
      className="app-block"
      key={index}
      data-aos="fade-left"
      data-aos-delay={100*index}
      data-aos-duration="700"
    >
      <div className="inner-box">
        <div className="content">
          <div className="icon-box">
            <span className={`icon iconax ${offer.icon}`}></span>
          </div>
          <h3>{offer.title}</h3>
          <div className="text">{offer.description}</div>
        </div>
      </div>
    </div>
  )
})

export default offers
