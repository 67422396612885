import React from 'react'

import Layout from '../components/layout'
import Services from '../components/Services'
import Offre from '../components/Offre'
import Contact from '../components/Contact'
import SEO from '../components/seo'
import Projects from '../components/Projects'

const IndexPage = () => (
  <Layout title='Web DS' desc="Vos solutions digitales" desc2="Agence web basée sur Sète">
    <SEO title="Web DS" />
    <Services />
    <Offre />
    <Projects />
    <Contact />
  </Layout>
)

export default IndexPage
