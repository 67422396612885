import React from 'react' 
import layer from '../images/background/pattern-4.webp'
import services from '../constants/services'

const Services = () => {
  return (
    <section className="services-section-two">
      <div
        className="image-layer"
        data-aos="fade-right"
        data-aos-delay="100"
        data-aos-duration="800"
        style={{ backgroundImage: `url(${layer})` }}
      ></div>

      <div className="auto-container">
        <div className="sec-title">
          <div
            className="title"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="800"
            id="services"
          >
            Nos Services
          </div>
          <div
            className="separator"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="800"
          >
            <span></span>
          </div>
          <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
            Fournisseur de <br /> Services Digitaux
          </h2>
        </div>
        <div className="inner-container">
          <div className="row clearfix">{services}</div>
        </div>
      </div>
    </section>
  )
}

export default Services
