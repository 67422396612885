import React from 'react'

const data = [
  {
    id: 1,
    icon: 'flaticon-bullhorn',
    title: 'Communication',
    description:' '
  },
  {
    id: 2,
    icon: 'flaticon-web-design',
    title: 'Développement web',
    description:
      'Créations sur mesures ou personnalisables en utilisant les technologies les plus fiables et innovantes ',
  },
  {
    id: 3,
    icon: 'flaticon-branding',
    title: 'Création de votre identité visuelle',
    description: 'Création sur mesure de votre logo, de votre identité visuelle',
  },
  {
    id: 4,
    icon: 'flaticon-deployment',
    title: 'Hébergement web',
    description:
      "L'hébergement de votre site internet est géré par nos soins ainsi que son renouvellement automatique",
  },
  {
    id: 5,
    icon: 'flaticon-domain',
    title: 'Nom de domaine',
    description:
      "Nous nous occupons de la création de votre nom de domaine ansi que de la création d'adresses mails professionnelles",
  },
]

const services = data.map((service, index) => {
  return (
    <div
      className={`service-block-two ${
        service.id == 1 ? 'col-lg-4' : 'col-lg-6'
      }  col-md-6 col-sm-12`}
      key={service.id}
      data-aos="fade-up"
      data-aos-delay={100 * index}
      data-aos-duration="700"
    >
      <div className="inner-box">
        <div className="shape-one"></div>
        <div className="shape-two"></div>
        <div className="shape-three"></div>
        <div className="icon-box">
          <span className={` ${service.icon}`}></span>
        </div>
        <h3 className="project-link">{service.title}</h3>
        <div className="text">{service.description}</div>
      </div>
    </div>
  )
})

export default services
