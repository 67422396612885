import React from 'react'
import offers from '../constants/offers'
import img3 from '../images/background/pattern-5.webp'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Offre = () => {
  return (
    <section className="choose-section">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="title-column col-lg-6 col-md-12 col-sm-12">
            <div
              className="inner-column"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <div className="sec-title">
                <div className="title" id="offer">
                  Agence la DS
                </div>
                <div className="separator">
                  <span></span>
                </div>
                <h2>
                  Création et déploiement <br /> de votre site web
                </h2>
              </div>
              <div className="text">Votre site personnalisé de A à Z</div>
              <ul className="list-style-two">
                <li>Création ou refonte de votre site ?</li>
                <li>Communication sur vos réseaux ?</li>
                <li>Création de votre logo ?</li>
              </ul>
              <AnchorLink to="/#contact" className="theme-btn btn-style-two">
                <span className="txt">Contactez nous</span>
              </AnchorLink>
            </div>
          </div>

          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="blocks-outer">{offers}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pattern-layer"
        style={{ backgroundImage: `url(${img3})` }}
        data-aos="fade-left"
        data-aos-delay="100"
        data-aos-duration="800"
      ></div>
    </section>
  )
}

export default Offre
