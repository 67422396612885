import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Project from './Project'

const Projects = () => {
  const query = graphql`
    {
      allContentfulProject(sort: { fields: contentful_id }) {
        projects: nodes {
          url
          title
          stack {
            stack
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const { projects } = data.allContentfulProject

  return (
    <section className="news-section">
      <div className="auto-container">
        <div
          className="sec-title centered"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <h2 id="projects">Derniers projets</h2>
          <div className="separator">
            <span></span>
          </div>
        </div>
        <div className="row clearfix">
          {projects.map((project, index) => {
            return (
              <div
                className="news-block col-lg-4 col-md-6 col-sm-12"
                key={index}
                data-aos="fade-up"
                data-aos-delay={200 * index}
                data-aos-duration="800"
              >
                <div className="inner-box wow fadeInLeft">
                  <Project project={project} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Projects
