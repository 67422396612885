import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: site.siteMetadata.keywords,
          },
          {
            property: `og:title`,
            content: `Web DS`,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: `https://web.agencelads.com/twitter.png`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:locale`,
            content: `fr_FR`,
          },
          {
            property: `og:url`,
            content: `https://web.agencelads.com/`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: `Web DS`,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: `https://web.agencelads.com/twitter.png`,
          },
        ].concat(meta)}
      >
        <link rel="canonical" href="https://web.agencelads.com/" />

        <script type="application/ld+json">
          {`
              { "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Web DS",
            "parentOrganization":{
              "@type": "Organization",
              "name": "Agence la DS",
              "url": "http://agencelads.com/"  
            },
            "brand":{
              "@type": "Service",
              "description": "Agence web bas\u00e9e sur Sète, disponible pour vos sites vitrines et ecommerce sur mesures, cr\u00e9ation de logo et communication sur vos r\u00e9seaux ! "
            ,"serviceType": "Agence web"
            },
              "areaServed": [
                {
                  "@type": "City",
                  "name": "Sete",
                  "@id": "https://fr.wikipedia.org/wiki/S%C3%A8te"
                },
                {
                  "@type": "City",
                  "name": "Montpellier",
                  "@id": "https://fr.wikipedia.org/wiki/Montpellier"
                }
              ],
            "url": "https://web.agencelads.com/",
            "logo": "https://www.agencelads.com/assets/img/logovrai.png",
            "foundingDate": "2020",
            "founders": [
            {
            "@type": "Person",
            "name": "Didier Sabatier"
            },
            {
            "@type": "Person",
            "name": "Manon Tisseur"
            },
            {
              "@type": "Person",
              "name": "Tom Sabatier"
            }
          ],
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "12 Allee du Grand Pavois",
            "addressLocality": "Sete",
            "addressRegion": "Occitanie",
            "postalCode": "34200",
            "addressCountry": "FR"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "telephone": "0633391524",
            "email": "contact@agencelads.com"
            },
            "sameAs": [ 
            "http://agencelads.com/",
            "https://www.facebook.com/agencelads",
            "https://twitter.com/Sabatier_Didier"
            ]}
      `}
        </script>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@graph": [
                    {
                        "@type": "WebSite",
                        "@id": "https://web.agencelads.com/#website",
                        "url": "https://web.agencelads.com/",
                        "name": "Web DS | Agence web Sete",
                        "description": "Agence web bas\u00e9e sur Sete, disponible pour vos sites vitrines et ecommerce sur mesures, cr\u00e9ation de logo et communication sur vos r\u00e9seaux ! ",
                        "inLanguage": "fr-FR"
                    },
                    {
                        "@type": "WebPage",
                        "@id": "https://web.agencelads.com/#webpage",
                        "url": "https://web.agencelads.com/",
                        "name": "Web DS | Agence Web Sete | Cr\u00e9ation de sites Internet",
                        "isPartOf": { "@id": "https://web.agencelads.com/#website" },
                        "description": "Agence web bas\u00e9e sur Sete, disponible pour vos sites vitrines et ecommerce sur mesures, cr\u00e9ation de logo, identit\u00e9 visuelle et communication sur vos r\u00e9seaux ! ",
                        "inLanguage": "fr-FR",
                        "potentialAction": [{ "@type": "ReadAction", "target": ["https://web.agencelads.com/"] }]
                    }
                ]
            }
          `}
        </script>
      </Helmet>
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
