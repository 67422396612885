import React from 'react'
import Img from 'gatsby-image'
import logo from '../images/logo.png'
import { FaLink } from 'react-icons/fa'

const Project = ({ project }) => {
  const { title, url } = project
  const { fluid } = project.image

  return (
    <>
      <div className="image">
        <a href={url} target="_blank">
          <Img fluid={fluid} alt={`projet ${title}`} />
        </a>
      </div>
      <div className="lower-content">
        <a href={url} className="project-link" target="_blank">
          <h4 className="text-center">{title}</h4>
        </a>
        <span></span>
        <div className="post-date"></div>
        <div className="clearfix">
          <div className="pull-left">
            <div className="author">
              <div className="image-projet">
                <img src={logo} alt="logo ds" />
              </div>
            </div>
          </div>{' '}
          <div className="pull-right">
            <ul className="post-info">
              <li>
                <a href={url} target="_blank" className="project-link">
                  <FaLink/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Project
